var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.data &&
     _vm.data.properties && _vm.data.properties.filed_content!=='Hide'
    ) ||
    _vm.isFromDocument
  )?_c('div',{staticStyle:{"display":"flex"},style:(_vm.getElementStyle)},[(_vm.data.group && _vm.data.group.key)?[_c('el-radio',{style:(_vm.getStyle),attrs:{"label":_vm.data && _vm.data.properties && !_vm.data.properties.hideLabel
          ? _vm.data.label
          : '',"disabled":_vm.isFromDocument ? false : _vm.isReadOnly() || _vm.checkReadonly() || _vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.group.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.group.key, $$v)},expression:"form[data.group.key]"}},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" "),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v("*")]):_vm._e(),(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"el-icon-info"})])]):_vm._e()],1)]:(_vm.isFromDocument)?[_c('el-radio',{class:{ 'highlighted-label': _vm.highlight },staticStyle:{"font-weight":"bold"},style:(_vm.getStyle),attrs:{"label":_vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.isReadOnly() || _vm.checkReadonly() || _vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")])]:[_c('el-radio',{class:{ 'highlighted-label': _vm.highlight },staticStyle:{"font-weight":"bold"},style:(_vm.getStyle),attrs:{"label":_vm.data && _vm.data.properties && !_vm.data.properties.hideLabel
          ? _vm.data.label
          : '',"disabled":_vm.isFromDocument ? false : _vm.isReadOnly() || _vm.checkReadonly() || _vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" "),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-right":"5px"}},[_vm._v("*")]):_vm._e(),(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"el-icon-info"})])]):_vm._e()],1)]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }